<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Elabore propostas comerciais detalhadas para clientes, contendo informações sobre produtos e preços. Orçamentos podem ser posteriormente resgatados no caixa.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data</label>
							<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="pesquisa.data"></datePicker>
                  </div>
                  <div class="col-md-8 col-lg-3 col-xxl-4 mb-3">
							<label><i class="far fa-receipt color-theme font-11 me-1"></i> Nome/CPF/CNPJ</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarOrcamentos" placeholder="Buscar por CPF/CNPJ, nome do cliente ou nome do orçamento" />
                  </div>
						<div class="col-md-4 col-lg-3 col-xxl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarOrcamentos"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Editar orçamentos')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<orcamento v-for="(orcamento, index) in pesquisa.resultado" :key="index" :orcamento="orcamento" :index="index" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>
	</div>
</template>

<script>

import router from '@/router'
import { mapState } from 'vuex'
import orcamento from '@/components/pdv/orcamentos/Orcamento.vue'

export default {
	name: 'Orçamentos',
	data: function () {
		return {
			pesquisa: {'loja': null, 'data': [new Date(), new Date()], 'valor': '', 'resultado': []}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
      'pesquisa.data' : function (value) {
         for (let i = 0; i < value.length; i++) {
            if (!(value[i] instanceof Date)) {
               value[i] = new Date(value[i])
            }
         }
		}
	},
	components: {
		orcamento
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Orcamentos',
				'resultado': this.pesquisa.resultado, 
				'campos': ['id', 'nome', 'nomeCliente', 'cpfCnpjCliente', 'data', 'nomeLoja', 'numeroLoja', 'quantidadeItens', 'valor'], 
				'lista': null
			})
      },
		abrirModal : function (orcamento) {
			let tabOrcamento = this.tabs.find(tab => tab.nome == 'Orçamento' && tab.query.id == (orcamento == null ? '' : orcamento.id))
			router.push('/orcamento/'+ (tabOrcamento ? tabOrcamento.id : this.tabs.length) +'?id='+ (orcamento == null ? '' : orcamento.id) +'&codigo='+ (orcamento == null ? 'Adicionar' : orcamento.codigo))
		},
		buscarOrcamentos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.resultado, this.$options.data.apply(this).pesquisa.resultado)

			this.$axios({
				method: 'post',
				url: this.urlRest +'pdv/searchOrcamentos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>