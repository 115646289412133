<template>
   <div class="col-12 px-0 mb-1" ref="orcamento">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">
                           <span v-if="orcamento.codigo">{{ orcamento.codigo }} - </span>
                           <span>{{ orcamento.nome == null || String(orcamento.nome).trim().length == 0 ? 'Sem nome' : orcamento.nome }}</span>
                        </h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span class="me-3"><i class="far fa-hashtag font-9 me-1"></i>{{ orcamento.id }}</span>
                           <span><i class="far fa-store font-8 me-1"></i>{{ orcamento.nomeLoja == null || String(orcamento.nomeLoja).trim().length == 0 ? 'Sem loja' : orcamento.nomeLoja }}</span>
                           <small v-if="orcamento.numeroLoja" class="mx-1">|</small>
                           <span v-if="orcamento.numeroLoja"><i class="far fa-tag font-8 me-1"></i>{{ orcamento.numeroLoja }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-xl-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> CPF/CNPJ Cliente:</strong>
                           <span>{{ orcamento.cpfCnpjCliente == null || String(orcamento.cpfCnpjCliente).trim().length == 0 ? 'Não definido' : orcamento.cpfCnpjCliente }}</span>
                           <small class="d-none d-sm-inline mx-2">|</small>
                           <span class="d-none d-sm-inline">{{ orcamento.nomeCliente == null || String(orcamento.nomeCliente).trim().length == 0 ? 'Sem cliente' : orcamento.nomeCliente }}</span>
                        </p>
                     </div>
                     <div class="col-xl-4 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Data:</strong><span>{{ orcamento.data }}</span>
                        </p>
                     </div>
                     <div class="col-6 col-xl-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Valor:</strong>
                           <span><small>R$ </small>{{ parseFloat(orcamento.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme" v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Editar orçamentos')">
                     <span @click="editar"><i class="far fa-pen"></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="dadosUsuario.usuarioPermissoes.includes('Caixa | Editar orçamentos')">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>


<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Orçamento',
   props: ['orcamento', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Caixa | Editar orçamentos')) {
            this.$emit('editar', this.orcamento)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir orçamento?',
            text: 'O orçamento será excluído, essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'pdv/deleteOrcamento',
                  params: {
                     id: this.orcamento.id  
                  }
					}).then(() => {
						this.$refs.orcamento.style.display = 'none'
		
						this.$toast.fire({
							icon: 'success',
							title: 'Orçamento excluído!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
      }
   }
}

</script>